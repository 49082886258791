@import url('https://fonts.googleapis.com/css2?family=Baskervville&family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baskervville&family=Luckiest+Guy&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
body {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
    /* background-color: aliceblue; */
}